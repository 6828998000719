import { Container, Divider, Typography } from "@mui/material";

export default function Footer() {
  return (
    <Container>
      <span className="separatorLarge" />
      <Divider />
      <span className="separatorLarge" />
      <Typography paragraph align={"center"}>
        Corso di Yoga a Erba: via Lodorina 20
      </Typography>
      <Typography paragraph align={"center"}>
        Telefono: <a href="tel:+393341627843">3341627843</a>
      </Typography>
      <Typography paragraph align={"center"}>
        Mail: <a href="mailto:bellulaura57@gmail.com">bellulaura57@gmail.com</a>
      </Typography>
    </Container>
  );
}
