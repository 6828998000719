import React from 'react';
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Card from "@mui/material/Card";
import { CardActionArea, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
  position: "absolute",
  top: "30px",
  left: "30px",
  bottom: "30px",
  right: "30px",
  backgroundColor: "#fff",
  padding: "6px",
  overflow: "auto",
  textAlign: "center",
  display: "block",
};

export default function Album(props) {
  const [isCardExpanded, setExpanded] = React.useState(false);
  const [isModalOpen, setOpen] = React.useState(false);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [imageSource, setImageSource] = React.useState("")
  const openModal = (imageSrc) => {
    setImageSource(imageSrc);
    setOpen(true);
  }

  const closeModal = () => setOpen(false);

  const handleExpandClick = () => {
    setExpanded(!isCardExpanded);
  };

  return (

    <React.Fragment>
      <Card>
        <CardActionArea onClick={handleExpandClick}>
          <CardMedia component="img" height="194" image={props.itemData[0]} />
          <CardContent>
            <Typography variant="h6" color="text.secondary">
              {props.title}
            </Typography>
          </CardContent>
        </CardActionArea>
        <Collapse in={isCardExpanded} timeout="auto" unmountOnExit>
          <CardContent>
            <ImageList cols={3}>
              {props.itemData.map((imageSrc) => (
                <ImageListItem key={imageSrc}>
                  <img
                    src={imageSrc}
                    loading="lazy"
                    style={{ objectFit: "cover" }}
                    onClick={() => openModal(imageSrc)}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </CardContent>
        </Collapse>

      </Card>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img src={imageSource} className='galleryOpenedImage' />
        </Box>
      </Modal>
    </React.Fragment>
  );
}
