import * as React from "react";
import styles from "../styles.css";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

export default function Evento(props) {
  return (
    <Paper
      sx={{ p: 2, margin: "auto", maxWidth: 500, flexGrow: 1 }}
      style={{ backgroundColor: "#ffeb3b54" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <div className="dataEvento">
            <Typography>{props.date}</Typography>
            <Typography>{props.time}</Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={8} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="div">
                {props.title}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {props.text}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
