import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#ba68c8',
      light: '#ee98fb',
      dark: '#883997'
    },
    secondary: {
      main: '#64b5f6',
      light: '#9be7ff',
      dark: '#2286c3',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
