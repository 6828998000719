import styles from "../styles.css";
import TabellaOrari from "../componenti/TabellaOrari";
import TabellaOrariFeldenkrais from "../componenti/TabellaOrariFeldenkrais";
import TabellaOrariQiGong from "../componenti/TabellaOrariQiGong";
import PageWrapper from "../componenti/PageWrapper";
import { Typography } from "@mui/material";

export default function Corsi() {
  return (
    <PageWrapper>
      <Typography variant={"h4"} align={"center"}>
        GLI ORARI DI YOGA
      </Typography>
      <span className="separatorLarge" />
      <Typography paragraph align={"center"}>
        Gli incontri di yoga avranno la durata di un’ora e saranno a cadenza
        settimanale, secondo gli orari indicati.
      </Typography>
      <Typography paragraph align={"center"}>
        Per la pratica sono necessari tappetino e cuscino da seduta (zafu).
      </Typography>
      <Typography paragraph align={"center"}>
        Negli incontri vengono proposte sequenze armoniche di movimenti,
        posizioni yoga, pause in cui risuona l’eco della pratica… <br />
        La pratica coinvolge corpo e mente, con una attenzione particolare al
        respiro. In essa diamo disponibilità all’ascolto di ciò che si presenta,
        senza giudizi o aspettative, così da vivere intensamente ogni momento.
      </Typography>

      <Typography paragraph align={"center"}>
        <span className="separatorSmall" />
        Durante l'anno prevediamo per i partecipanti lezioni teorico/pratiche su argomenti specifici, quali <strong>rilassamento, introduzione alla meditazione, respiro e pranayama.</strong> <br />
        Se interessati iscrivetevi quanto prima per garantirvi uno spazio e favorire l'organizzazione. <br />
      </Typography>

      <span className="separatorLarge" />
      <TabellaOrari />
      <span className="separatorLarge" />

      <Typography paragraph align={"center"}>
        Oltre agli incontri settimanali vengono periodicamente proposte attività
        parallele, quali bagni di gong, serate a tema, vacanze – yoga, ecc.,
        puntualmente annunciate nelle news del sito.
      </Typography>


      <span className="separatorLarge" />
      <Typography variant={"h4"} align={"center"}>
        GLI ORARI DI FELDENKRAIS
      </Typography>
      <span className="separatorLarge" />
      <TabellaOrariFeldenkrais />
      <span className="separatorSmall" />
      <Typography align={"center"}>PER INFO ED ISCRIZIONI: Priscilla <a href="tel:+393471044290">3471044290</a> </Typography>
      <span className="separatorLarge" />
      <span className="separatorLarge" />
      <Typography variant={"h4"} align={"center"}>
        GLI ORARI DI QI GONG
      </Typography>
      <span className="separatorLarge" />
      <TabellaOrariQiGong />
      <span className="separatorSmall" />
      <Typography align={"center"}> PER INFO E ISCRIZIONI: Anna <a href="tel:+393388697573">3388697573</a> </Typography>
      <span className="separatorLarge" />
    </PageWrapper>
  );
}
