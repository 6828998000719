
import * as React from "react";

export function Logo() {
  return (
    <React.Fragment>
      <span className="separatorSmall" />
      <div className="logo">Spazio Yoga MAHA DEVI</div>
      <span className="separatorLarge" />
    </React.Fragment>
  )
}