import * as React from "react";
import Home from "./pagine/Home";
import Corsi from "./pagine/Corsi";
import News from "./pagine/News";
import Passati from "./pagine/Passati";
import { useMediaQuery } from "react-responsive";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Galleria from "./pagine/Galleria";

export default function App() {
  const [value, setValue] = React.useState("home");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            centered
            orientation={isMobile ? "vertical" : "horizontal"}
          >
            <Tab value="home" label="Home" />
            <Tab value="corsi" label="Corsi e Orari" />
            <Tab value="news" label="News" />
            <Tab value="passati" label="Eventi Passati" />
            <Tab value="galleria" label="Galleria" />
          </TabList>
        </Box>
        <TabPanel value="home" style={{ padding: "0px" }}>
          <Home />
        </TabPanel>
        <TabPanel value="corsi" style={{ padding: "0px" }}>
          <Corsi />
        </TabPanel>
        <TabPanel value="news" style={{ padding: "0px" }}>
          <News />
        </TabPanel>
        <TabPanel value="passati" style={{ padding: "0px" }}>
          <Passati />
        </TabPanel>
        <TabPanel value="galleria" style={{ padding: "0px" }}>
          <Galleria />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
