import foto_rosa1 from "../images/rosa1.jpeg";
import foto_fiore1 from "../images/fiore1.jpeg";
import foto_laura from "../images/laura.jpeg";
import foto_sede2 from "../images/sede2.jpeg";
import CardComponent from "../componenti/CardComponent";
import PageWrapper from "../componenti/PageWrapper";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import styles from "../styles.css";

export default function Home() {
  return (
    <PageWrapper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardComponent
            text={<Typography variant={"subtitle2"}>Questo spazio yoga è dedicato alla Grande Madre,
              cioè all'aspetto femminile della Forza di Vita che è ricezione, generazione, nutrimento, cura, ascolto e accoglienza.<br />
              Così questo spazio è e resterà uno spazio INCLUSIVO, APERTO A TUTTI, ACCOGLIENTE, SENZA DISTINZIONI E DISCRIMINAZIONI.</Typography>}
          />
          <span className="separatorSmall" />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardComponent
            image={foto_sede2}
            title="LA SEDE"
            text="La sede dello “Spazio Yoga Maha Devi” si trova a Erba (Como), nella frazione di Buccinigo, in via Lodorina 20.
          E’ situata in un ex-fienile ristrutturato, un luogo interamente dedicato allo yoga. Vi è possibilità di parcheggio."
          />
          <span className="separatorSmall" />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardComponent
            image={foto_laura}
            title={`L’INSEGNANTE DI YOGA`}
            text={`Ho praticato per molti anni con allievi di Gérard Blitz, discepolo di
          Krisnamacharya e con loro mi son formata all’insegnamento presso
          l’Istituto Internazionale di Ricerche Yoga (IIRY) nella sede di
          Milano.
          Ho conseguito un secondo diploma abilitante all’insegnamento
          con André Van Lysebeth, pioniere dello yoga in occidente. Entrambi i
          diplomi sono riconosciuti dall’Unione Europea di Yoga-UEY.
          Ho seguito poi molti seminari e corsi di approfondimento in Italia e all’estero,
          specialmente l’insegnamento di Eric Baret, maestro dello yoga del
          Cachemir. Sono socia YANI (associazione nazionale insegnanti yoga). Mi
          sono avvicinata allo yoga oltre 30 anni fa e ancora questa arte mi
          appassiona. Per me l’aspetto più interessante è il ritrovare nella
          pratica spazi sacri di silenzio, ma anche altri elementi sono
          affascinanti: la gioia del movimento nello spazio, la libertà del
          respiro e la consapevolezza dell’energia che il respiro porta con sé…
          Per questo ed altro ancora continuo a praticare e ad insegnare, per
          quanto posso dare in base alla mia esperienza e grata per ciò che ho
          ricevuto.`}
          />
          <span className="separatorSmall" />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardComponent
            image={foto_fiore1}
            title={`LO YOGA`}
            text={`Lo yoga è un’ arte antica eppur sempre nuova. E’ ricerca ed
          espressione di armonia, di unione con le forze cosmiche che abitano il
          corpo e lo vivificano. Nella pratica dello yoga, con la sensibilità
          esploriamo lo spazio interiore del corpo e lo spazio che lo circonda,
          per accogliere la vita così come si presenta, nell’istante. A questo
          ci invita lo yoga: ad essere uno con la vita, nell’istante.
          "Siamo qui per risvegliarci dall’illusione della separatezza"
          Thich Nhat Hanh`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardComponent
            image={foto_rosa1}
            title={"CORSI DI HATHA E RAJA YOGA"}
            text={`"Lasciate che i petali si schiudano. Possiamo rilassarci nella gioia
              di essere ciò che siamo e nel rilassamento fioriscono umiltà,
              innocenza e rapporti amorevoli." Vimala Thakar Visita la pagina Corsi
              e orari per saperne di più sui corsi di yoga a Erba.`}
          />
          <span className="separatorSmall" />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardComponent
            // image={foto_laura}
            title={`L'INSEGNANTE DI QI GONG`}
            text={<Typography variant="body2">Anna Molteni ha una formazione triennale di qi gong e meditazione taoista presso la scuola Tao di Bologna
              e ha proseguito con ulteriori approfondimenti.
              È naturopata, massoterapista e operatrice olistica. <br /><br />
              IL QI GONG è una antica pratica orientale per prevenire e curare numerosi disturbi,
              migliorare respiro, vitalità e postura,
              favorire il rilassamento e la relazione con la propria parte spirituale. <br /><br />
              PER INFO E ISCRIZIONI: Anna 3388697573
            </Typography>}
          />
          <span className="separatorSmall" />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardComponent
            // image={foto_laura}
            title={`L'INSEGNANTE DI FELDENKRAIS`}
            text={<Typography variant="body2">
              Priscilla Pizziol è abilitata all'insegnamento con formazione quadriennale e sta proseguendo lo studio per ulteriori specializzazioni.
              Diplomata all'Accademia di danza contemporanea di Salisburgo è una danzatrice professionista. <br /><br />
              IL METODO FELDENKRAIS è metodo di autoeducazione e consapevolezza tramite il movimento.
              È una pratica dolce attraverso cui è possibile conoscersi meglio, conoscere il proprio corpo, la relazione fra le parti,
              le proprie abitudini motorie ed aprirsi alla possibilità di cambiarle. <br /><br />
              PER INFO ED ISCRIZIONI: Priscilla 3471044290
            </Typography>}
          />
          <span className="separatorSmall" />
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
