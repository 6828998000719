import * as React from "react";
import PageWrapper from "../componenti/PageWrapper";
import Album from "../componenti/Album";
import { Typography } from "@mui/material";
import Grid from '@mui/material/Grid';

export default function Galleria() {
  const fotovarie = importAll(
    require.context("../images/fotovarie/", false, /\.(png|jpe?g|svg)$/)
  );
  const cannero2017 = importAll(
    require.context(
      "../images/canneroRiviera2017/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  const cannero2019 = importAll(
    require.context(
      "../images/canneroRiviera2019/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );

  return (
    <PageWrapper>
      <Typography variant={"h4"} align={"center"}>
        GALLERIA
      </Typography>
      <span className="separatorLarge" />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Album itemData={fotovarie} title={"Foto varie"} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Album itemData={cannero2019} title={"Cannero Riviera 2019"} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Album itemData={cannero2017} title={"Cannero Riviera 2017"} />
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
function importAll(images) {
  return images.keys().map(images);
}
