import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import styles from "../styles.css";

export default function CardComponent(props) {
  return (
    <Card>
      {props.image && (
        <CardMedia component="img" alt="" height="350" image={props.image} />
      )}
      <CardContent>
        {props.title && (
          <Typography gutterBottom variant={"h4"} component="div">
            {props.title}
          </Typography>
        )}
        {props.text && (
          <Typography variant="body2" color="text.secondary">
            {props.text}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
