import styles from "../styles.css";
import { Typography } from "@mui/material";
import PageWrapper from "../componenti/PageWrapper";
import Evento from "../componenti/Evento";

export default function News() {
  return (
    <PageWrapper>
      <Typography variant={"h4"} align={"center"}>
        NEWS
      </Typography>
      <span className="separatorLarge" />
      <Evento
        // time={"ore 9:30"}
        date={"23 Settembre 2024"}
        title={
          "Corsi di yoga"
        }
        text={
          <Typography variant="body2">
            I Corsi di yoga riprenderanno Lunedi 23 settembre, guarda <a href="../images/eventi/corsi24.jpeg" target="_blank">qui</a> il volantino con orari e novità
            <br /><br />
            Per info e prenotazione:
            Laura Bellù 3341627843
          </Typography>
        }
      />
      <span className="separatorSmall" />
    </PageWrapper>
  );
}
