import styles from "../styles.css";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: "#ffeb3b54",
}));

export default function TabellaOrari() {
  return (
    <Grid container spacing={2} justifyContent="center">
      {/* <Grid item xs={12} md={3}>
        <Item>
          <Typography variant={"h6"} align={"center"}>
            Lunedi
          </Typography>
          <span className="separatorSmall" />
          <Typography>
            18.15 - 19.15 <br /> 19.45 - 20.45
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={12} md={3}>
        <Item>
          <Typography variant={"h6"} align={"center"}>
            Martedi
          </Typography>
          <span className="separatorSmall" />
          <Typography>
            18.00 - 19.00 <br /> 19.30 - 20.30
          </Typography>
        </Item>
      </Grid> */}
      <Grid item xs={12} md={3}>
        <Item>
          <Typography variant={"h6"} align={"center"}>
            Mercoledi
          </Typography>
          <span className="separatorSmall" />
          <Typography>
            18.00 - 19.00
          </Typography>
        </Item>
      </Grid>
      {/* <Grid item xs={12} md={3}>
        <Item>
          <Typography variant={"h6"} align={"center"}>
            Giovedi
          </Typography>
          <span className="separatorSmall" />
          <Typography>
            9.30 - 10.30 <br /> 18.15 - 19.15
          </Typography>
        </Item>
      </Grid> */}
    </Grid>
  );
}
