import { Logo } from "./Logo";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Footer from "./Footer";
import * as React from "react";

export default function PageWrapper(props) {
  return (
    <React.Fragment>
      <Container maxWidth="md">
        <Box sx={{ width: "100%" }}>
          <Logo />
          {props.children}
        </Box>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
